<template>
  <div>
    <vx-card title="حدث خطأ" class="mb-2" title-color="danger" v-if="Object.keys(errors_values).length">

      <div v-for="(key, index) in Object.keys(errors_values)" :key="index">

        <span v-for="(error, index) in errors_values[key]" :key="index" class="text-danger">
          {{ error }}
        </span>
      </div>

    </vx-card>
    <vx-card>
      <h1>إضافة منتج جديد</h1>
      <div class="mb-5">&#8203;</div>
      <h4 class="mt-8">صور المنتج</h4>
      <div class="vx-row bottom-seperator">
        <div class="vx-col my-1 w-full mb-6">
          <div class="con-img-upload flex" id="images" v-if="product.media.length">
            <div class="img-upload" v-for="(image, index) in product.media" :key="index">
              <button type="button" class="btn-x-file" @click="spliceFile(index)">
                <i translate="translate" class="material-icons notranslate">clear</i>
              </button>
              <img v-if="image.blob" :src="image.blob" style="max-width: none; max-height: 20px" />
            </div>
          </div>
          <file-upload ref="upload" :multiple="true" v-model="product.media" class="vs-button bg-primary mt-5"
            post-action="/post.method" put-action="/put.method" @input-filter="inputFilter"> تنزيل صورة
          </file-upload>

          <span class="text-danger text-sm" v-show="media_error">{{
          media_error
          }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
          <div>
            <div class="vx-row">
              <div class="vx-col my-3 sm:w-full w-full mb-2">
                <vs-input data-vv-as="اسم المنتج" v-validate="'required'" class="w-full" label-placeholder="إسم المنتج"
                  v-model="product.name" name="name" />
                <span class="text-danger text-sm" v-show="errors.has('name')">{{
                errors.first("name")
                }}</span>
              </div>
            </div>
            <div class="vx-row mt-8">
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <vs-input data-vv-as="رقم SKU" v-validate="'required'" class="w-full" label-placeholder="رقم SKU"
                  v-model="product.sku" name="sku" />
                <span class="text-danger text-sm" v-show="errors.has('sku')">{{
                errors.first("sku")
                }}</span>
              </div>
              <div class="vx-row sm:w-1/2 w-full pl-10 mb-2">
                <div class="mb-2"><label for="">العلامة التجارية</label></div>
                <select data-vv-as="العلامة التجارية" v-validate="'required'" v-model="product.brand_id"
                  class="w-full h-12" name="brand">
                  <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                    {{ brand.name }}
                  </option>
                </select>

                <span class="text-danger text-sm" v-show="errors.has('brand')">{{
                errors.first("brand")
                }}</span>
              </div>



              <div class="vx-row w-full mt-10">
                <div class="vx-col my-3 sm:w-1/3 w-full ml-5 mb-10">
                  <vs-input data-vv-as="سعر المنتج" v-validate="'required|numeric|min:0'" type="number" class="w-full"
                    name="price" label-placeholder="سعر المنتج" v-model="product.price" />
                  <span class="text-danger text-sm" v-show="errors.has('price')">{{
                  errors.first("price")
                  }}</span>
                </div>
                <div class="vx-col my-3 sm:w-1/3 w-full">
                  <vs-input data-vv-as="السعر بعد التخفيض" v-validate="'numeric|min:0'" type="number" class="w-full"
                    name="new_price" label-placeholder="السعر بعد التخفيض" v-model="product.new_price" />
                  <span class="text-danger text-sm" v-show="errors.has('new_price')">{{
                  errors.first("new_price")
                  }}</span>
                </div>

                <div class="vx-col my-3 sm:w-1/4 w-full">
                  <vs-input data-vv-as="الكمية" v-validate="'required|numeric|min:0'" type="number" class="w-full"
                    label-placeholder="الكمية" name="quantity" v-model="product.quantity" />
                  <span class="text-danger text-sm" v-show="errors.has('quantity')">{{
                  errors.first("quantity")
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- // category -->
          <div class="vx-row mt-8 mb-20">
            <div class="vx-col sm:w-1/2 w-full mb-6">
              <div class="mb-2"><label for="">القسم الرئيسي</label></div>
              <v-select label="name" data-vv-as="القسم الرئيسي" v-model="main_category" :options="categories"
                v-validate="'required'" :dir="$vs.rtl ? 'rtl' : 'ltr'" name="main_catgeory" class="w-full"></v-select>

              <span class="text-danger text-sm" v-show="errors.has('main_catgeory')">{{
              errors.first("main_catgeory")
              }}</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-6">
              <div class="mb-2"><label for="">القسم الفرعي</label></div>
              <v-select label="name" data-vv-as="القسم الرئيسي" v-model="product.sub_category"
                :options="main_category.sub_categories" v-validate="'required'" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                name="sub_category" class="w-full"></v-select>

              <span class="text-danger text-sm" v-show="errors.has('sub_category')">{{
              errors.first("sub_category")
              }}</span>
            </div>
            <div class="vx-col sm:w-full w-full mb-6">
              <div class="mb-2"><label for="">نسبة الزيادة</label></div>
              <vs-input data-vv-as="نسبة الزيادة" type="number" class="w-full"
                    name="percentage" label-placeholder="نسبة الزيادة" v-model="product.percentage" />
                  <span class="text-danger text-sm" v-show="errors.has('percentage')">{{
                  errors.first("percentage")
                  }}</span>
             
            </div>
          </div>
        </div>


        <!-- product descreption -->
        <div class="vx-col mt-3 sm:w-1/2 w-full mb-6 seperator">
          <label for="">وصف المنتج</label>
          <vs-textarea class="mt-3 pb-20" rows="10" placeholder="وصف المنتج بالكامل و لما يحمله من خصائص"
            v-model="product.description" />
          <div class="vx-row sm:w-full w-full">
            <vs-checkbox class="mx-5" v-model="product.is_new">
              هل المنتج جديد
            </vs-checkbox>

            <vs-checkbox class="mx-5" v-model="product.is_featured">
              تمييز في الصفحة الرئيسية
            </vs-checkbox>
          </div>
        </div>
      </div>

      <!-- submitting -->
      <div class="vx-row mt-8 btn-alignment">
        <div class="vx-col sm:w-full w-full mb-6">
          <vs-button class="mr-5 text-lg py-4" @click="reset" color="warning" type="border">تفريغ الحقول</vs-button>
          <vs-button class="mr-5 text-lg px-16 py-4" @click="submit" color="success" type="filled">حفظ</vs-button>
        </div>
      </div>



    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VueUploadComponent from 'vue-upload-component'
// Store Module
import moduleProduct from "@/store/products/moduleProduct.js";
export default {
  components: {
    vSelect,
    'file-upload': VueUploadComponent
  },
  data() {
    return {
      main_category: {},
      // brands come from db
      errors_values: {},
      media_error: '',
      product: {
        name: "",
        sku: "",
        price: null,
        new_price: 0,
        brand_id: "",
        quantity: null,
        description: "",
        media: [],
        is_new: false,
        is_featured: false,
        sub_category: "",
        percentage:''
      },
    };
  },
  computed: {
    categories() {
      return this.$store.state.product.Categories;
    },
    brands() {
      return this.$store.state.product.brands;
    },
  },

  methods: {
    reset() {
      this.product = {
        name: "",
        sku: "",
        price: null,
        new_price: 0,
        media: [],
        brand_id: "",
        quantity: null,
        description: "",
        is_new: false,
        is_featured: false,
        sub_category: "",
        percentage:''
      };
    },
    spliceFile(index) {
      this.product.media.splice(index, 1);
    },
    inputFilter(newFile, oldFile, prevent) {
      if ((newFile.size / 1024.0) > 5000) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
          color: "danger",
        });
        return prevent();
      }
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يجب أن يكون الملف بإمتداد صورة صحيح",
            color: "danger",
          });
          return prevent();
        }
      }
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var bodyFormData = new FormData();
          bodyFormData.set("name", this.product.name);
          bodyFormData.set("sku", this.product.sku);
          bodyFormData.set("price", this.product.price);
          bodyFormData.set("new_price", this.product.new_price || 0);
          bodyFormData.set("brand_id", this.product.brand_id);
          bodyFormData.set("description", this.product.description);
          bodyFormData.set("quantity", this.product.quantity);
          bodyFormData.set("is_new", this.product.is_new ? 1 : 0);
          bodyFormData.set("is_featured", this.product.is_featured ? 1 : 0);
          bodyFormData.set("sub_category_id", this.product.sub_category.id);
          bodyFormData.set("category_id", this.main_category.id);
          bodyFormData.set("percentage", this.product.percentage);


          for (var i = 0; i < this.product.media.length; i++) {
            let file = this.product.media[i].file;
            bodyFormData.append("media[" + i + "]", file);
          }

          this.$store
            .dispatch("product/createNewProduct", bodyFormData)
            .then(() => {
              // this.reset(); disabled to disble rerouting and keep data from being reset on submission
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تم اضافة المنتج بنجاح",
                color: "success",
              });
              // this.$router.push({ name: "products" }); disabled to disable rerouting and prevent page from sending you back to products page

            }).catch((err) => {

              this.errors_values = err.response.data.errors;
              this.$vs.notify({
                title: "حدث خطأ",
                text: err.response.data.message,
                color: "danger",
              });

              if (err.response.data.errors && err.response.data.errors.media) {
                this.media_error = 'حقل الصورة مطلوب يرجى اضافة على الاقل صورة واحدة'
              }
            })
        }
      });
    },

    // to change sub category optins whenever main category change
    // handleSelect() {
    //   this.subCategories = [];
    //   this.product.subCategory = "";
    //   let options = this.subCategoryOptions[this.product.mainCategory];
    //   options.forEach((option) => {
    //     this.subCategories.push(option);
    //   });
    // },
  },
  created() {
    if (!moduleProduct.isRegistered) {
      this.$store.registerModule("product", moduleProduct);
      moduleProduct.isRegistered = true;
    }
    this.$store.dispatch("product/fetchCategories");
    this.$store.dispatch("product/fetchBrands");
  },
};
</script>

<style lang="scss">
.btn-upload-file {
  display: none;
}

.seperator {
  border-left: 1px solid rgb(204, 204, 204);

}

.bottom-seperator {
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 4em;
}

.btn-alignment {
  text-align: right;
}
</style>
